@import "susy";
@import "breakpoint";

// Include Basic Elements, titles, lists etc
@import "base/_essentials.scss";

@import "variables/_grid.scss";
@import "variables/_sprites.scss";
@import "variables/_colors.scss";
@import "variables/_typography.scss";

/* Makes border-box properties NOT!!! */

/* --------- from global.css -------------- */

.page-node .field-name-field-images .field-item {
  display: inline; }

.page-node-71 .field-name-body .field-items .foreis {
  border-bottom: 1px solid #D3D3D3;
  margin-bottom: 20px;
  padding-bottom: 20px; }

article {
  text-align: justify; }

.views-field-body {
  text-align: justify; }

/* ------ end of Kelly CSS ----------- */
a:hover, a:focus {
  text-decoration: underline; }

body {
  font: 400 14px/1.5 "Fira Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo-img {
  position: absolute;
  top: 0;
  z-index: 10;
  width: 260px;
  height: 177px;
  background: url("../images/logo-bg.png") left top no-repeat;
  overflow: hidden; }
.logo-img #logo {
  position: relative;
  top: -2px;
  left: 0; }

.front .logo-img {
  height: 290px;
  background: url("../images/logo-bg-front.png") left top no-repeat; }
.front .logo-img #logo {
  border-top: 3px solid #004877;
  position: static; }

a:link, a:active, a:visited {
  text-decoration: none; }

.view-id-content_block.view-display-id-block_2 .view-footer {
  clear: both;
  margin-top: 20px;
  text-align: right;
  padding-top: 20px;
  font-size: 1.16666em; }

#region-menu {
  margin-left: 300px; }
#region-menu .block-block-2 {
  // position: absolute;
  // right: 10px;
  // top: 124px;
  // z-index: 10;
  // width: 310px;
  // height: 50px;
  // background: url("../images/social-media-header.png") left top;
  float: right;
  margin: 32px 20px 0 0;
}

.block-block-2 {
  .facebook {
    @include sprite($sprite-facebook-icon);

    &:hover,
    &:focus {
      @include sprite($sprite-facebook-icon);
    }
  }

  .rss {
    @include sprite($sprite-rss-icon);

    &:hover,
    &:focus {
      @include sprite($sprite-rss-icon);
    }
  }
}

#region-menu .block-block-2 ul.social-media {
  // padding: 12px 20px 12px 44px;
  a {
    &:last-child {
      li {
        margin-right: 0;
      }
    }
  }
}

#region-menu .block-block-2 ul.social-media li {
  cursor: pointer;
  float: left;
  margin-right: 15px;
}

#region-menu .block-block-2 ul.social-media li.google-plus {
  margin-right: 10px;
}

#region-menu a:link, #region-menu a:active, #region-menu a:visited, #region-menu span {
  color: white; }
#region-menu a:link:focus, #region-menu a:link:hover, #region-menu a:active:focus, #region-menu a:active:hover, #region-menu a:visited:focus, #region-menu a:visited:hover, #region-menu span:focus, #region-menu span:hover {
  color: #F6891E;
  text-decoration: none;
  cursor: pointer; }
#region-menu .block-superfish {
  position: relative;
  z-index: 12;
  padding-left: 20px;
  background: url("../images/menu-bg.png") left top no-repeat; }
#region-menu li span {
  display: block; }
#region-menu ul.sf-main-menu {
  display: block; }
#region-menu ul.sf-main-menu li > ul {
  margin-top: 5px;
  left: -5px;
  background: #004877;
  padding-right: 10px; }
#region-menu ul.sf-main-menu li > ul li {
  margin: 0; }
#region-menu ul.sf-main-menu li > ul li a.menuparent {
  background: url("../images/arrow-third-level.png") no-repeat right center; }
#region-menu ul.sf-main-menu li > ul li a {
  padding: 12px 6px 12px 10px; }
#region-menu ul.sf-main-menu li > a, #region-menu ul.sf-main-menu li > span {
  padding: 16px 7px 10px; }

#views_slideshow_cycle_main_slideshow_view-block_1 {
  padding-left: 280px; }

.block-block-9 {
  position: absolute;
  top: 22px;
  left: 46px; }

.front .logo-img {
  position: absolute;
  top: 126px; }
.front .feed-icon {
  display: none; }
.front #zone-slideshow {
  height: 340px;
  background: url("../images/slider-bg.png") left top no-repeat; }
.front #zone-slideshow-wrapper {
  position: relative;
  z-index: 2;
  background: url("../images/slider-all-bg.png") left top repeat;
  border-top: 4px solid #004877;
  border-bottom: 4px solid #004877; }

#zone-slideshow-tabs-wrapper {
  background: url("../images/slideshow-shadow.png") 0 -44px repeat-x; }

#zone-preface .block-block-3 {
  border: 1px solid #D3D3D3;
  padding: 10px;
  margin: 10px 0;
  font: 400 1.4285em "Roboto Condensed";
  color: #004877; }
#zone-preface .block-block-3 a {
  color: #004877; }
#zone-preface .block-block-3 a:hover, #zone-preface .block-block-3 a:focus {
  color: #0081B5; }
#zone-preface .block-block-3 .item {
  float: left;
  padding: 15px 20px 15px 47px; }
#zone-preface .block-block-3 .item-1 {
  background: url("../images/item-1.png") left center no-repeat; }
#zone-preface .block-block-3 .item-2 {
  background: url("../images/item-2.png") left center no-repeat; }
#zone-preface .block-block-3 .item-3 {
  margin-right: 0;
  background: url("../images/item-3.png") left center no-repeat;
  padding-right: 0px; }

#block-block-6,
.block-block-6-instance {
  margin-top: 80px; }

.block-block-6,
.block-block-6-instance {
  border: 1px solid #D3D3D3;
  padding: 10px;
  margin: 20px 0;
  font: 400 1.4285em "Roboto Condensed";
  color: #004877; }
.block-block-6 .col,
.block-block-6-instance .col {
  float: left; }
.block-block-6 .high,
.block-block-6-instance .high {
  margin-bottom: 20px; }
.block-block-6 a:link, .block-block-6 a:visited, .block-block-6 a:active,
.block-block-6-instance a:link, .block-block-6-instance a:visited, .block-block-6-instance a:active {
  color: #004877; }
.block-block-6 a:link:hover, .block-block-6 a:link:focus, .block-block-6 a:visited:hover, .block-block-6 a:visited:focus, .block-block-6 a:active:hover, .block-block-6 a:active:focus,
.block-block-6-instance a:link:hover, .block-block-6-instance a:link:focus, .block-block-6-instance a:visited:hover, .block-block-6-instance a:visited:focus, .block-block-6-instance a:active:hover, .block-block-6-instance a:active:focus {
  color: #0081B5;
  text-decoration: none; }
.block-block-6 .item,
.block-block-6-instance .item {
  padding: 15px 12px 15px 47px; }
.block-block-6 .low-item-1,
.block-block-6-instance .low-item-1 {
  background: url("../images/item-1.png") left center no-repeat; }
.block-block-6 .low-item-2,
.block-block-6-instance .low-item-2 {
  background: url("../images/item-2.png") left center no-repeat; }
.block-block-6 .low-item-3,
.block-block-6-instance .low-item-3 {
  margin-right: 0;
  background: url("../images/item-3.png") left center no-repeat;
  padding-right: 0; }
.block-block-6 .high-item-1,
.block-block-6-instance .high-item-1 {
  background: url("../images/high-item-1.png") left center no-repeat; }
.block-block-6 .high-item-2,
.block-block-6-instance .high-item-2 {
  background: url("../images/high-item-2.png") left center no-repeat; }
.block-block-6 .high-item-3,
.block-block-6-instance .high-item-3{
  margin-right: 0;
  background: url("../images/high-item-3.png") left center no-repeat;
  padding-right: 0; }

.tweeter {
  width: 30px;
  height: 28px;
  background: url("../images/social-media-sprite.png") no-repeat 0 0; }
.tweeter:hover, .tweeter:focus {
  background: url("../images/social-media-sprite.png") no-repeat 0 -35px; }

.linkedin {
  width: 24px;
  height: 28px;
  background: url("../images/social-media-sprite.png") no-repeat -40px 0; }
.linkedin:hover, .linkedin:focus {
  background: url("../images/social-media-sprite.png") no-repeat -40px -34px; }

.facebook {
  width: 12px;
  height: 28px;
  background: url("../images/social-media-sprite.png") no-repeat -75px 0; }
.facebook:hover, .facebook:focus {
  background: url("../images/social-media-sprite.png") no-repeat -75px -34px; }

.youtube {
  width: 25px;
  height: 28px;
  background: url("../images/social-media-sprite.png") no-repeat -96px 0; }
.youtube:hover, .youtube:focus {
  background: url("../images/social-media-sprite.png") no-repeat -96px -34px; }

.google-plus {
  width: 30px;
  height: 28px;
  background: url("../images/social-media-sprite.png") no-repeat -130px 0; }
.google-plus:hover, .google-plus:focus {
  background: url("../images/social-media-sprite.png") no-repeat -130px -34px; }

.rss {
  width: 30px;
  height: 28px;
  background: url("../images/social-media-sprite.png") no-repeat -164px 0; }
.rss:hover, .rss:focus {
  background: url("../images/social-media-sprite.png") no-repeat -164px -34px; }

#zone-branding {
  position: relative; }

#block-search-form .search-btn {
  display: block;
  float: right;
  height: 24px;
  padding: 10px 10px 6px 6px;
  border-top: 1px solid #C1C1C1;
  border-bottom: 1px solid #C1C1C1;
  border-right: 1px solid #C1C1C1;
  border-left: none;
  position: relative; }
#block-search-form input {
  color: #0081B5;
  height: 28px;
  border-top: 1px solid #C1C1C1;
  border-bottom: 1px solid #C1C1C1;
  border-left: 1px solid #C1C1C1;
  border-right: none;
  padding: 6px;
  width: 180px; }
#block-search-form input.focused {
  color: #202020; }
#block-search-form input.form-submit {
  height: auto;
  border: none;
  padding: 0;
  margin-left: 5px;
  width: auto; }

.view-slideshow-view {
  margin-top: 60px; }
.view-slideshow-view .views-field-body p {
  line-height: 150%;
  margin-bottom: 0.8em; }
.view-slideshow-view .views-field-title {
  font-size: 2em;
  line-height: 125%;
  margin-bottom: 10px; }
.view-slideshow-view .views-field-title a {
  color: white; }
.view-slideshow-view .views-field-created, .view-slideshow-view .views-field-field-event {
  color: #004877;
  padding-left: 26px;
  margin-bottom: 5px;
  font-size: 1.2857em;
  font-family: "Roboto Condensed", sans-serif;
  background: url("../images/clock-slider.png") left center no-repeat; }
.view-slideshow-view .views-field-field-event {
  padding-left: 30px;
  background: url("../images/calendar-slider.png") left bottom no-repeat; }
.view-slideshow-view .views-field-field-event .views-label {
  font-size: 0.6666em;
  line-height: 130%; }
.view-slideshow-view .views-slideshow-controls-bottom {
  margin-top: 20px;
  text-align: center;
  padding-bottom: 10px; }
.view-slideshow-view .views-slideshow-controls-bottom .views-slideshow-pager-field-item {
  margin-right: 4px;
  display: inline-block;
  width: 17px;
  height: 17px;
  background: url("../images/inactive-slide.png") left center no-repeat;
  text-indent: -9999px;
  overflow: hidden;
  cursor: pointer; }
.view-slideshow-view .views-slideshow-controls-bottom .views-slideshow-pager-field-item.active {
  background: url("../images/active-slide.png") left center no-repeat; }
.view-slideshow-view .views-field-view-node {
  position: relative;
  margin-top: 20px; }
.view-slideshow-view .views-field-view-node a {
  position: absolute;
  right: 0;
  display: block;
  width: 146px;
  height: 32px;
  background: url("../images/read-more-slider.png") left center no-repeat;
  color: white;
  line-height: 32px;
  font-size: 0.8571em;
  vertical-align: middle;
  text-align: center; }
.view-slideshow-view .views-field-view-node a:hover, .view-slideshow-view .views-field-view-node a:focus {
  text-decoration: underline; }
.view-slideshow-view .views-field-body {
  font-size: 1.2857em;
  color: white; }

.slide-tab {
  float: left;
  margin-left: 20px;
  width: 380px;
  height: 54px;
  background: url("../images/slide-tab.png") left top no-repeat; }
.slide-tab .text-inside {
  font: 400 1.5714em "Roboto Condensed", sans-serif;
  color: white;
  display: block;
  padding: 15px 5px 12px 50px;
  letter-spacing: -0.02em; }
.slide-tab .text-inside:hover, .slide-tab .text-inside:focus {
  color: #F6891E; }
.slide-tab a.slide-tab-link:hover, .slide-tab a.slide-tab-link:focus {
  color: #F6891E; }
.slide-tab.tab-1 {
  margin-left: 0; }
.slide-tab.tab-1 .text-inside {
  padding-left: 55px;
  background: url("../images/first-tab.png") 10px center no-repeat; }
.slide-tab.tab-2 .text-inside {
  padding-left: 58px;
  background: url("../images/second-tab.png") 7px center no-repeat; }
.slide-tab.tab-3 .text-inside {
  padding: 15px 0 12px 50px;
  background: url("../images/third-tab.png") 8px center no-repeat;
  letter-spacing: -0.03em; }

#zone-slideshow-tabs {
  position: relative;
  top: -4px; }

h2.block-title {
  color: #004877;
  font-size: 1.5714em;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  margin-bottom: 20px; }

.region-sidebar-second .block {
  margin-bottom: 20px; }

.block-views-content-block-block {
  font-family: "Roboto Condensed", sans-serif; }
.block-views-content-block-block h2.block-title {
  padding-left: 45px;
  background: url("../images/faq-bubble.png") left center no-repeat; }
.block-views-content-block-block .faq-list ul {
  list-style: none; }
.block-views-content-block-block .faq-list ul li {
  background: url("../images/square.png") left center no-repeat;
  border-bottom: 1px solid white; }
.block-views-content-block-block .faq-list ul li.views-row-last {
  border-bottom: none; }
.block-views-content-block-block .view-content {
  padding: 0 10px;
  color: white;
  background: #0081B5; }
.block-views-content-block-block .view-content .views-row {
  padding: 10px 10px 10px 15px; }
.block-views-content-block-block .view-content a {
  color: white; }

a:link, a:visited, a:active {
  color: #0081B5; }
a:focus, a:hover {
  text-decoration: underline; }

.block-views-content-block-block-1 .view-footer {
  clear: both;
  text-align: right;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #D3D3D3; }
.block-views-content-block-block-1 .view-footer .read-more {
  font-weight: 400; }
.block-views-content-block-block-1 .views-field-view-node {
  margin-top: 10px;
  font-size: 0.8571em;
  text-align: right; }
.block-views-content-block-block-1 .views-field-view-node a {
  color: #0081B5; }
.block-views-content-block-block-1 .views-field-view-node a:hover, .block-views-content-block-block-1 .views-field-view-node a:focus {
  text-decoration: underline; }
.block-views-content-block-block-1 .views-row {
  width: 360px;
  float: left;
  margin-right: 40px;
  margin-bottom: 40px; }
.block-views-content-block-block-1 .views-row-even {
  margin-right: 0; }
.block-views-content-block-block-1 .views-field-nothing {
  position: relative;
  margin-bottom: 5px;
  padding-bottom: 3px;
  border-bottom: 1px solid #D3D3D3;
  color: #686868; }
.block-views-content-block-block-1 .views-field-nothing .category {
  position: absolute;
  right: 0; }
.block-views-content-block-block-1 .views-field-nothing .category a {
  color: #686868; }
.block-views-content-block-block-1 .views-field-nothing .category a:hover, .block-views-content-block-block-1 .views-field-nothing .category a:focus {
  text-decoration: underline; }
.block-views-content-block-block-1 .views-field-title h2 {
  font-size: 1.4285em;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  margin-bottom: 5px;
  line-height: 150%; }
.block-views-content-block-block-1 .views-field-title h2 a {
  color: #0081B5; }
.block-views-content-block-block-1 .views-field-title h2 a:hover, .block-views-content-block-block-1 .views-field-title h2 a:focus {
  text-decoration: underline; }

p {
  margin-bottom: 1em; }

.region-content ul {
  list-style: disc;
  margin-left: 40px; }
.region-content ol {
  list-style: decimal;
  margin-left: 40px; }
.region-content sup {
  vertical-align: super;
  font-size: smaller; }
.region-content sub {
  vertical-align: sub;
  font-size: smaller; }

/* ---- EOF All Sites ----------- */
.block-view-nea-kentrwn-block-1 {
  margin-top: 20px; }
.block-view-nea-kentrwn-block-1 .block-title {
  padding-left: 36px;
  background: url("../images/rss-kentra.png") no-repeat left center; }

.view-view-nea-kentrwn {
  border: 1px solid #C1C1C1; }
.view-view-nea-kentrwn .view-footer {
  position: relative;
  height: 0; }
.view-view-nea-kentrwn .arrow-below {
  background: url("../images/kentra-arrow.png") no-repeat center top;
  height: 12px; }
.view-view-nea-kentrwn .views-row {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #D3D3D3; }
.view-view-nea-kentrwn .views-row a {
  color: #686868; }
.view-view-nea-kentrwn .views-row a:focus, .view-view-nea-kentrwn .views-row a:hover {
  color: #0081B5; }
.view-view-nea-kentrwn .view-content {
  padding: 10px; }
.view-view-nea-kentrwn .views-row-last {
  border-bottom: none; }
.view-view-nea-kentrwn .views-field-title span {
  display: block;
  padding-left: 40px; }
.view-view-nea-kentrwn .views-field-title span a:hover, .view-view-nea-kentrwn .views-field-title span a:focus {
  text-decoration: none; }
.view-view-nea-kentrwn .views-field-title span.feed-id-1 {
  background: url("../images/feed-id-1.png") no-repeat left center; }
.view-view-nea-kentrwn .views-field-title span.feed-id-2 {
  background: url("../images/feed-id-2.png") no-repeat left center; }

/* jCarousel */
.block-views-content-block-block-2 {
  margin-bottom: 40px; }

.view-carousel .views-field-field-event-date {
  margin-bottom: 6px; }

.block-views-content-block-block-2 {
  font-size: 0.8571em; }
.block-views-content-block-block-2 .views-field-title {
  margin-top: 5px; }

.block-views-content-block-block-2 .views-field-title {
  margin-top: 5px; }

.jcarousel-skin-default .jcarousel-container-horizontal {
  height: 240px;
  margin: auto;
  padding: 0;
  width: 1200px; }

.jcarousel-skin-default .jcarousel-clip-horizontal {
  overflow: hidden;
  width: 1120px;
  margin: 0 40px; }

.section-footer {
  margin-top: 40px; }

.jcarousel-skin-default .jcarousel-item {
  padding: 10px;
  border: 1px solid #D3D3D3;
  height: 240px;
  list-style: none outside none;
  overflow: hidden;
  width: 250px; }
.jcarousel-skin-default .jcarousel-item .views-field-field-event {
  color: #686868;
  font-size: 1.1666em; }
.jcarousel-skin-default .jcarousel-item .views-field-field-image {
  margin: 5px 0;
  float: none; }

.jcarousel-skin-default .jcarousel-next, .jcarousel-skin-default .jcarousel-prev {
  display: block;
  height: 28px;
  width: 28px; }

.jcarousel-skin-default .jcarousel-next {
  background-image: url("../images/carousel-arrow-right.png");
  background-repeat: no-repeat; }

.jcarousel-skin-default .jcarousel-prev {
  background-image: url("../images/carousel-arrow-left.png");
  background-repeat: no-repeat; }

.jcarousel-skin-default .jcarousel-prev-horizontal {
  background-position: 0;
  left: 0;
  position: absolute;
  top: 120px; }

.jcarousel-skin-default .jcarousel-next-horizontal {
  position: absolute;
  top: 120px;
  right: 0;
  background-position: 0; }

.jcarousel-skin-default .jcarousel-prev-horizontal:hover {
  background-position: 0; }

.jcarousel-skin-default .jcarousel-prev-horizontal:active {
  background-position: 0; }

.jcarousel-skin-default .jcarousel-next-horizontal:hover {
  background-position: 0; }

.jcarousel-skin-default .jcarousel-next-horizontal:active {
  background-position: 0; }

/* end of jCarousel */
.zone-postscript-wrapper {
  background: url("../images/footer-bg.png") left top repeat;
  border-bottom: 4px solid #0081B5;
  margin-top: 70px; }

#zone-content {
  padding-bottom: 20px;
}

.block-block-4 {
  position: absolute;
  top: -80px;
  right: 0;
  width: 97px;
  height: 62px;
  background: url("../images/social-media-footer.png") no-repeat left top;

  ul.social-media {
    padding: 20px;

    a {
      &:last-child li {
        margin-right: 0;
      }
    }
  }
}

.block-block-4 ul.social-media li {
  cursor: pointer;
  float: left;
  margin-right: 15px;
}

.block-block-4 ul.social-media li.google-plus {
  margin-right: 10px;
}

.region-postscript-first {
  margin-left: 180px; }

#zone-postscript {
  background: url("../images/footer-menu-bg.jpg") center center no-repeat;
  padding: 80px 0 20px;
  min-height: 260px;
  color: white; }
#zone-postscript a {
  color: white; }
#zone-postscript a:focus, #zone-postscript a:hover {
  color: #F6891E;
  text-decoration: none; }
#zone-postscript ul.menu > li {
  padding: 0 20px;
  margin-bottom: 20px;
  margin-left: 0;
  display: block;
  float: left;
  width: 250px;
  height: 250px;
  border-right: 1px solid white;
  border-left: 1px solid #004063; }
#zone-postscript ul.menu > li.first {
  border-left: none;
  background: url("../images/border-bg.png") repeat-y left top; }
#zone-postscript ul.menu > li.last {
  background: url("../images/border-bg.png") repeat-y right top;
  border-right: none; }
#zone-postscript ul.menu li > a, #zone-postscript ul.menu li > span {
  font-size: 1.2857em; }
#zone-postscript ul.menu > li ul.menu > li {
  border-right: none;
  border-left: none;
  font-size: 0.7777em;
  font-weight: 400;
  margin: 0;
  padding: 5px 0;
  float: none;
  width: auto;
  height: auto; }
#zone-postscript ul.menu > li ul.menu > li.first {
  background: none; }
#zone-postscript ul.menu > li ul.menu > li.last {
  background: none; }
#zone-postscript ul.menu > li ul.menu {
  margin: 0;
  padding: 0; }

.espa-sponsor {
  padding: 10px;
  text-align: left;
  width: 570px;
  display: inline-block; }

.copyright {
  font-size: 0.8571em;
  color: #686868;
  text-align: right;
  width: 570px;
  display: inline-block; }
.copyright span {
  position: relative;
  top: -28px; }

#designed {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.8571em;
  color: #0081B5;
  text-align: right; }
#designed a:link, #designed a:visited, #designed a:active {
  color: #0081B5; }
#designed a:hover, #designed a:focus {
  color: #F6891E; }

#section-footer {
  margin-top: 20px; }

.block-views-content-block-block-2 h2.block-title {
  background: url("../images/calendar-icon.png") no-repeat left center;
  padding-left: 38px; }

.front #zone-branding-wrapper {
  border: none; }
.front #section-content {
  padding-top: 0; }

#section-content {
  padding-top: 30px; }

#zone-branding-wrapper {
  border-bottom: 2px solid #004877; }

#page-title {
  font-size: 1.5714em;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #D3D3D3;
  color: #004877; }

.view-content-view .views-field-field-image, .view-taxonomy-term .views-field-field-image {
  float: left;
  margin-right: 10px;
  margin-bottom: 5px; }
.view-content-view .views-field-title, .view-taxonomy-term .views-field-title {
  font-size: 1.4285em;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
  color: #0081B5; }
.view-content-view .views-field-view-node, .view-taxonomy-term .views-field-view-node {
  margin-top: 10px;
  text-align: right;
  font-size: 0.8571em; }
.view-content-view .news-category, .view-taxonomy-term .news-category {
  margin-bottom: 0; }
.view-content-view .news-category a, .view-taxonomy-term .news-category a {
  color: #686868;
  font-size: 0.8571em; }
.view-content-view .meta-info, .view-taxonomy-term .meta-info {
  position: relative;
  margin-bottom: 5px;
  color: #686868;
  font-size: 0.8571em; }
.view-content-view .meta-info .news-category, .view-taxonomy-term .meta-info .news-category {
  position: absolute;
  right: 0; }
.view-content-view .views-row, .view-taxonomy-term .views-row {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #D3D3D3; }
.view-content-view .views-row-last, .view-taxonomy-term .views-row-last {
  border-bottom: none; }

.field-name-field-video {
  margin: 20px 0; }

.node-events {
  /*
  .field-name-field-related-news {
    margin: 20px 0;
    clear: both;

    .field-items {
      padding-left: 8px;
    }

    .field-item {
      background: url('../images/bullet.png') left center no-repeat;
      padding-left: 16px;

      margin-bottom: 10px;
    }
  }
  */ }
.node-events .field-name-body {
  min-height: 240px; }
.node-events .field-name-field-event {
  padding-bottom: 10px;
  margin: 10px 0;
  color: #686868;
  font-size: 1.2857em;
  padding-left: 28px;
  background: url("../images/node-calendar.png") left top no-repeat; }

.field-label {
  color: #686868; }

.node-page .field-name-body img {
  margin-right: 20px;
  margin-bottom: 20px; }
.node-page .field-name-field-attachments {
  clear: both;
  position: relative;
  border-top: 1px dashed #0081B5;
  margin: 20px 0; }
.node-page .field-name-field-attachments .field-items {
  padding-top: 10px; }
.node-page .field-name-field-attachments .field-items a {
  color: #004877; }

.node-news .field-name-body img, .node-events .field-name-body img {
  margin-right: 20px;
  margin-bottom: 20px; }
.node-news .field-name-field-image, .node-events .field-name-field-image {
  margin-right: 15px;
  margin-bottom: 15px;
  float: left; }
.node-news .field-name-field-images, .node-events .field-name-field-images {
  margin: 20px 0; }
.node-news .field-name-field-images .field-item, .node-events .field-name-field-images .field-item {
  margin: 0 10px 10px 0;
  float: left; }
.node-news .field-name-field-news-source, .node-events .field-name-field-news-source {
  margin: 10px 0; }
.node-news .group-meta-info, .node-events .group-meta-info {
  border-bottom: 1px solid #D3D3D3;
  margin-bottom: 20px;
  padding-bottom: 5px;
  position: relative; }
.node-news .group-meta-info .custom-format-date, .node-events .group-meta-info .custom-format-date {
  color: #686868; }
.node-news .group-meta-info .field-name-field-news-category, .node-events .group-meta-info .field-name-field-news-category {
  position: absolute;
  top: 0;
  right: 0; }
.node-news .group-meta-info .field-name-field-news-category a, .node-events .group-meta-info .field-name-field-news-category a {
  color: #686868; }
.node-news .field-name-field-attachments, .node-events .field-name-field-attachments {
  clear: both;
  position: relative;
  border-top: 1px dashed #0081B5;
  margin: 20px 0; }
.node-news .field-name-field-attachments .field-items, .node-events .field-name-field-attachments .field-items {
  padding-top: 10px; }
.node-news .field-name-field-attachments .field-items a, .node-events .field-name-field-attachments .field-items a {
  color: #004877; }

.view-content-view.view-display-id-page_1 .views-field-field-event {
  margin-bottom: 10px;
  color: #686868;
  padding-left: 20px;
  background: url("../images/small-calendar.png") no-repeat left top; }

.file-scissor {
  display: block;
  position: absolute;
  top: -10px;
  right: 10px;
  background: url("../images/scissor.png") no-repeat 0 0;
  width: 28px;
  height: 17px; }

.block-main {
  margin-bottom: 20px; }

.node-mediator .field-label {
  font-weight: 400; }

.page-mitrwo-diamesolavitwn h1#page-title {
  background: url("../images/item-1.png") no-repeat left center;
  padding: 15px 0;
  padding-left: 50px; }

.addtoany-label {
  font-family: "Roboto Condensed", sans-serif;
  color: #004877;
  margin-bottom: 10px; }

.addtoany-wrapper {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #D3D3D3; }

.view-mediator-search .view-header {
  margin-bottom: 20px;
  color: #004877; }
.view-mediator-search .view-header a {
  font-weight: 700; }
.view-mediator-search .views-row {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #D3D3D3; }
.view-mediator-search .views-row-last {
  border-bottom: none; }
.view-mediator-search .views-field-title h2 {
  font-size: 1.4285em;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  margin-bottom: 5px;
  line-height: 150%;
  color: #0081B5; }
.view-mediator-search .field-label {
  font-weight: 400; }

.block-views-exp-mediator-search-page #autocomplete {
  min-width: 360px !important; }
.block-views-exp-mediator-search-page .view-empty {
  color: #F6891E; }
.block-views-exp-mediator-search-page .views-exposed-form {
  padding: 20px 0; }
.block-views-exp-mediator-search-page .views-exposed-widget label {
  font-weight: 400;
  font-size: 1.2857em;
  margin-bottom: 5px;
  color: #0081B5;
  font-family: "Roboto Condensed", sans-serif; }
.block-views-exp-mediator-search-page .views-exposed-widget .description {
  font-size: 0.8571em;
  color: #686868;
  margin-top: 5px; }
.block-views-exp-mediator-search-page .form-item-field-mediator-city-value, .block-views-exp-mediator-search-page .views-widget-filter-field_mediator_city_value {
  max-width: 280px; }
.block-views-exp-mediator-search-page .form-text.error {
  color: #202020; }
.block-views-exp-mediator-search-page .form-item input {
  padding: 8px 12px;
  border: 1px solid #D3D3D3; }
.block-views-exp-mediator-search-page .form-item select {
  padding: 8px 12px;
  border: 1px solid #D3D3D3; }
.block-views-exp-mediator-search-page input[type="submit"] {
  background: #0081B5;
  color: white;
  border: none;
  padding: 8px 10px;
  position: relative;
  top: 12px; }
.block-views-exp-mediator-search-page input[type="submit"]:hover, .block-views-exp-mediator-search-page input[type="submit"]:focus {
  cursor: pointer;
  background: #F6891E; }
.block-views-exp-mediator-search-page .views-submit-button {
  padding: 0.5em 0.5em 0 0; }

.view-content-view, .view-taxonomy-term, .view-mediator-search {
  /* Pager */ }
.view-content-view .item-list .pager, .view-taxonomy-term .item-list .pager, .view-mediator-search .item-list .pager {
  background: white;
  border: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  margin-top: 15px;
  border-top: 1px solid #D3D3D3;
  padding-top: 15px; }
.view-content-view .item-list .pager li, .view-taxonomy-term .item-list .pager li, .view-mediator-search .item-list .pager li {
  border: none;
  margin: 2px; }
.view-content-view .item-list .pager li a, .view-taxonomy-term .item-list .pager li a, .view-mediator-search .item-list .pager li a {
  background: none;
  color: #202020;
  font-size: 14px; }
.view-content-view .item-list .pager li a:hover, .view-taxonomy-term .item-list .pager li a:hover, .view-mediator-search .item-list .pager li a:hover {
  color: #0081B5; }
.view-content-view .item-list li.pager-current, .view-taxonomy-term .item-list li.pager-current, .view-mediator-search .item-list li.pager-current {
  background: #0081B5;
  color: white; }
.view-content-view .item-list li.pager-current, .view-taxonomy-term .item-list li.pager-current, .view-mediator-search .item-list li.pager-current {
  border-radius: 12px;
  padding: 5px 8px;
  border-radius: 0\9; }

.view-syxnes-erotiseis,
.view-accordion {
  .ui-accordion {
    .views-row {
      img {
        margin-right: 20px;
        margin-bottom: 20px;
       }
    }

    .ui-accordion-content {
      padding: 1em;
      font-size: 14px;
    }

    .views-accordion-header {
      margin-bottom: 0;
      padding-left: 28px;
      background: $deep_blue;
      background: linear-gradient($deep_blue 80%, $cyan 110%);
      border: none;

      a {
        color: white;

        &:hover,
        &:focus {
          color: #F6891E;
        }
      }

      .ui-icon-triangle-1-e {
        width: 11px;
        height: 9px;
        position: absolute;
        left: 10px;
        top: 22px;
        background: url("../images/arrow-third-level.png") left center no-repeat;
      }

      .ui-icon-triangle-1-s {
        position: absolute;
        left: 10px;
        top: 22px;
        width: 11px;
        height: 9px;
        background: url("../images/arrow-third-level.png") left center no-repeat;
      }
    }
  }
}

.view-accordion {
  .views-exposed-form {
    margin-bottom: 30px;

    #edit-field-question-value-wrapper {
      width: 400px;
      padding: 0;
      margin-right: 20px;
    }

    label {
      font-size: 16px;
      font-weight: 400;
      padding-bottom: 5px;
    }

    input[type="text"] {
      width: 100%;
      height: 32px;
      border: 1px solid $border;
      padding: 5px 10px;
      box-sizing: border-box;
    }

    .views-submit-button,
    .views-reset-button {
      padding: 8px 0 0;
    }

    .views-reset-button {
      margin-left: 20px;

      input {
        background: #708090;

        &:hover,
        &:focus {
          background: $orange;
        }
      }
    }
  }
}

.block-views-content-block-block-3 .view-footer {
  font-size: 0.8571em;
  text-align: right;
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid #D3D3D3; }
.block-views-content-block-block-3 .view-footer .read-more {
  font-weight: 400; }
.block-views-content-block-block-3 .views-row {
  margin-bottom: 10px;
  padding-bottom: 10px; }
.block-views-content-block-block-3 .views-field-nothing {
  position: relative;
  margin-bottom: 5px;
  padding-bottom: 3px;
  border-bottom: 1px solid #D3D3D3;
  color: #686868; }
.block-views-content-block-block-3 .views-field-nothing .category {
  position: absolute;
  right: 0; }
.block-views-content-block-block-3 .views-field-nothing .category a {
  color: #686868; }
.block-views-content-block-block-3 .views-field-nothing .category a:hover, .block-views-content-block-block-3 .views-field-nothing .category a:focus {
  text-decoration: underline; }
.block-views-content-block-block-3 .views-field-title h2 {
  font-size: 1.4285em;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 150%; }
.block-views-content-block-block-3 .views-field-title h2 a {
  color: #0081B5; }
.block-views-content-block-block-3 .views-field-title h2 a:hover, .block-views-content-block-block-3 .views-field-title h2 a:focus {
  text-decoration: underline; }

.block-newsletter {
  position: absolute;
  top: -140px;
  right: 420px;
  height: 60px;
  left: 0;
  right: 0;
  width: 429px;
  margin: 0 auto;
}
.block-newsletter .form-item-mergevars-EMAIL {
  margin-top: 5px; }
.block-newsletter h2.block-title {
  margin-bottom: 0;
  line-height: 140%; }
.block-newsletter .mailchimp-signup-subscribe-form {
  position: relative; }
.block-newsletter #edit-mergevars-email {
  width: 400px;
  padding: 10px;
  color: #D3D3D3;
  font-size: 1.2857em;
  border: 4px solid #004877; }
.block-newsletter #edit-mergevars-email.text-hovered {
  color: #F6891E; }
.block-newsletter input[type="image"] {
  position: absolute;
  top: 10px;
  right: 10px; }

.feed-icon {
  text-align: right; }

/* Kelly CSS */
input[type="submit"] {
  padding: 8px 12px;
  background: #004877;
  color: white;
  border: none;
  font-family: "Fira Sans", sans-serif; }
input[type="submit"]:hover, input[type="submit"]:focus {
  background: #F6891E;
  cursor: pointer; }

.page-node .field-name-field-images .field-item {
  display: inline; }

.page-node-71 .field-name-body .field-items .foreis {
  border-bottom: 1px solid #D3D3D3;
  margin-bottom: 20px;
  padding-bottom: 20px; }

.webform-client-form .form-text {
  padding: 8px;
  border: 1px solid #D3D3D3; }
.webform-client-form .form-textarea {
  padding: 8px;
  border: 1px solid #D3D3D3;
  min-height: 160px; }
.webform-client-form .form-item .description {
  color: #686868; }

.search-form + h2 {
  margin: 20px 0;
  font-weight: 400;
  color: #0081B5;
  font-size: 1.2857em; }
.search-form label[for="edit-keys"] {
  color: #686868; }
.search-form .form-text {
  padding: 8px;
  border: 1px solid #D3D3D3; }
.search-form input.form-submit {
  position: relative;
  top: -2px;
  left: 5px; }

ol.search-results .search-result {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #D3D3D3; }
ol.search-results .search-result:last-child {
  border-bottom: none; }

.related-news-title {
  margin-bottom: 10px;
  color: #0081B5;
  padding-bottom: 5px;
  border-bottom: 1px solid #D3D3D3; }

.view-content-block .views-field-field-image {
  margin-right: 10px;
  margin-bottom: 5px;
  float: left; }
.view-content-block.view-display-id-block_4 .views-row {
  margin-bottom: 20px;
  padding-bottom: 20px; }
.view-content-block.view-display-id-block_4 .views-row-last {
  margin-bottom: 0;
  padding-bottom: 0; }
.view-content-block.view-display-id-block_4 .node-title {
  font-size: 1.4285em;
  font-family: "Roboto Condensed", sans-serif;
  line-height: 140%;
  font-weight: 400;
  margin-bottom: 10px; }
.view-content-block.view-display-id-block_4 .node-title a {
  color: #004877; }

.front .block-system-main {
  margin-bottom: 0; }

.views_slideshow_controls_text_pause {
  display: none; }

.views_slideshow_controls_text span {
  display: block;
  width: 17px;
  height: 17px;
  text-indent: -9999px;
  overflow: hidden; }

.views-slideshow-controls-text-previous {
  position: absolute;
  left: 18px;
  bottom: 75px;
  z-index: 100;
  background: url("../images/arrow_faq_1.png") left top no-repeat; }

#views_slideshow_controls_text_slideshow_faq-block {
  z-index: 12;
  position: relative;
  height: 0; }

.views-slideshow-controls-text-next {
  position: absolute;
  right: 18px;
  bottom: 75px;
  z-index: 100;
  background: url("../images/arrow_faq_2.png") right top no-repeat; }

/*# sourceMappingURL=global.css.map */

hr{
  border: 1px solid #D3D3D3;
  border-right: none;
  border-left: none;
  border-top: none;
  height: 0px;
  margin-top: 15px;
  margin-bottom: 20px;
}

/* --------- end global.css --------------- */

#views-exposed-form-mediator-search-users-page {
  .views-widget-filter-user {
    display: none;
  }
}

.page-user {
  .user-login {
    .form-actions {
      float: left;
      margin-right: 8px;
      position: relative;
      top: -19px;
    }
  }
}

.description-bl {
  clear: both;
  margin-bottom: 20px;
  font-size: 12px;

  &__orange {
    color: $orange;
  }
}

#block-gtweaks-member-links-button {
  float: right;
  position: relative;
  top: 22px;
  // width: 450px;
  // text-align: right;

  .item-list {

    .menu-ro-7 {
      min-width: 140px;
    }

    .menu-ro-6 {
      min-width: 140px;
    }

    .menu-ro-5 {
      min-width: 230px;
    }

    &:before {
      content: "";
      @include sprite($sprite-user_area);
      display: block;
      float: left;
      margin-right: 8px;
      position: relative;
      top: 1px;
    }

    @include clearfix;

    li {
      margin: 0;
      display: block;
      float: left;

      &:after {
        content: "|";
        display: inline;
        margin: 6px;
        color: $cyan;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

  .block-inner {
    a {
      color: $cyan;
    }
  }

  .block-inner {
    .login-link {
      margin-top: 2px;
      display: inline-block;

      a {
        padding: 8px 20px 10px;
        background: $cyan;
        color: white;
        // width: 180px;
        font-size: $very-small;
        display: inline-block;
        text-align: center;
        background: $deep_blue;
        background: linear-gradient(to right, $cyan, $deep_blue);
        border-radius: 20px;
        transition: all 0.4s;

        &:hover,
        &:focus {
          color: $white;
          // background: $orange;
          box-shadow: 0 3px 5px rgba($black,0.5);
          text-decoration: underline;
        }

        &:before {
          display: inline-block;
          top: 2px;
          position: relative;
          margin-right: 5px;
          content: "";
          @include sprite($sprite-anon_login);
        }
      }

      &.citizen {
        margin-left: 15px;
        a {
          padding: 8px 35px 10px;
        }
      }
    }
  }
}

.page-mitrwo-diamesolavitwn,
.page-mitrwo-diamesolavitwn-new {
  h1#page-title {
    background: url("../images/item-1.png") no-repeat left center;
    padding: 15px 0;
    padding-left: 50px;
  }
}

.view-mediator-search {
  .view-header {
    margin-bottom: 20px;
    color: #004877;
    a {
      font-weight: 700;
    }
  }
  .views-row {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D3D3D3;
  }
  .views-row-last {
    border-bottom: none;
  }
  .views-field-title h2 {
    font-size: 1.4285em;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 400;
    margin-bottom: 5px;
    line-height: 150%;
    color: #0081B5;
  }
  .field-label {
    font-weight: 400;
  }
}

.block-views-exp-mediator-search-page,
.view-mediator-search-users {
  #autocomplete {
    min-width: 360px !important;
  }
  .view-empty {
    color: #F6891E;
    font-size: $normal;
    text-align: center;
  }
  .views-exposed-form {
    padding: 20px 0 70px;
  }
  .views-exposed-widget {

    .form-submit {
      margin-top: 19px;
    }

    label {
      font-weight: 400;
      font-size: $normal;
      margin-bottom: 5px;
      color: $dark-grey;
      font-family: "Roboto Condensed", sans-serif;
    }
    .description {
      font-size: 0.8571em;
      color: #686868;
      margin-top: 5px;
    }
  }

  .form-type-textfield  {
    // max-width: 206px;

    input {
      // max-width: 174px;
    }
  }

  .form-text.error {
    color: #202020;
  }

  .form-item {
    input, select {
      padding: 8px 18px 8px 12px;
      border: 1px solid #D3D3D3;
    }
  }
  input[type="submit"] {
    background: #0081B5;
    color: white;
    border: none;
    padding: 8px 10px;
    position: relative;
    top: 12px;
    &:hover, &:focus {
      cursor: pointer;
      background: #F6891E;
    }
  }
  .views-submit-button {
    padding: 0.5em 0.5em 0 0;
  }
}

.view-mediator-search-users {

  .views-field-nothing {
    margin-top: 3px;
  }

  .view-header {
    // margin-bottom: 20px;
    // color: #004877;
  }

  .views-row {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D3D3D3;
  }
  .views-row-last {
    border-bottom: none;
  }

  .views-field-field-mediator-surname h2 {
    font-size: 1.4285em;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 400;
    margin-bottom: 5px;
    line-height: 150%;
    color: #0081B5;
  }

  .views-field {
    .field-content {
      display: inline;
    }
  }

  .views-label {
    font-weight: 400;
    color: #686868;
  }

    .item-list .pager {
      background: white;
      border: none;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0;
      border-top: 1px solid #D3D3D3;
      padding-top: 15px;
      margin: 70px 0 0;
    }


    .item-list .pager li {
      border: none;
      margin: 2px;

      &.pager-current {
        background: #0081B5;
        color: white;
        border-radius: 12px;
        padding: 5px 8px;
        border-radius: 0\9;
      }

      a {
        background: none;
        color: #202020;
        font-size: 14px;

        &:hover {
          color: #0081B5;
        }
      }
    }

}

#user-pass-reset {

  #edit-account {
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #D3D3D3;

    + p {
      color: $orange;
    }
  }
}

// login override stuff

.bo {
  color: $orange;
  font-weight: 700;
}

#block-search-form {
  width: 218px;
  position: relative;
  float: right;
  top: 22px;
  margin-right: 15px;
  padding-bottom: 44px;

  .search-btn {
    position: absolute;
    right: 0;
    top: 0;
    float: none;
  }
}

.views-slideshow-pager-fields {
  > div {
    padding-left: 2px;
  }
}

#block-superfish-1 {
  clear: both;
}

.forgot-pass-sb {
  margin-left: 12px;
}

div.notification {
  margin: 20px 0;
  padding: 12px;
  font-weight: 700;
  border: 1px solid #004877;
  color: $orange;
}

/*2019*/

//Login Form//
.node-type-prototypes,
.page-node-7743,
.page-node-7749,
.page-aitimata,
.node-type-request,
.page-node-add-request {
  #page-title {
    border-bottom: none;
    padding-bottom: 0;
  }

  .addtoany-wrapper {
    display: none;
  }
}

.node-prototypes {
  .field-item {
    position: relative;
    float: left;
    width: span(4);
    margin-right: gutter();
    box-shadow: 5px 5px 10px rgba($black, 0.1);
    padding: 40px 30px;
    box-sizing: border-box;
    margin-bottom: 20px;
    text-align: left;

    &:hover {
      background: $light-grey;
    }

    &:before {
      content: "";
      @include sprite($sprite-file-icon);
      margin-right: 20px;
      display: inline-block;
      vertical-align: middle;
    }

    &:after {
      content: "";
      @include sprite($sprite-download-icon);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 30px;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(3n+1) {
      clear: left;
    }

    a {
      display: inline-block;
      vertical-align: middle;
      color: $black;
      max-width: 210px;

      &:hover,
      &:focus {
        text-decoration: none;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    img {
      display: none;
    }
  }
}

.page-node-7750 {
  &.node-type-prototypes {

    #page-title {
      padding-bottom: 10px;
      margin: 0 0 20px;
      border-bottom: 1px solid $border;
    }

    .field-item {
      width: span(6);

      &:nth-child(3n) {
        margin-right: gutter();
      }

      &:nth-child(3n+1) {
        clear: none;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(2n+1) {
        clear: left;
      }
    }
  }
}

.page-aitimata {
  .view-diamesolavisi-requests,
  .view-citizen-requests {
    table {
      width: 100%;
      text-align: center;
      border-collapse: separate;
      border-spacing: 0px 20px;
    }

    th {
      text-align: center;
      color: $dark-grey;
    }

    tbody {
      tr {
        background: $white;
        // box-shadow: 0 0 7px rgba($black, 0.1);
        border-bottom: 0;
        position: relative;

        td {
          &.small {
            &:first-child:before {
              height: 66px;
            }
          }

          &:first-child:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            z-index: -1;
            display: block;
            height: 87px;
            box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
            -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
            padding: 20px;
            margin-top: -23px;
            box-sizing: border-box;
          }
        }
      }

      td {
        padding: 20px 15px;
        vertical-align: middle;
      }
    }

    .views-field-mediators-request--status--accept {
      .accept-link {
        display: inline-block;
        padding-right: 20px;

        > a {
          @include sprite($sprite-accept-icon);
          display: block;
          color: transparent;
          text-indent: -9999px;
        }
      }

      .reject-link {
        display: inline-block;
        > a {
          @include sprite($sprite-reject-icon);
          display: block;
          color: transparent;
          text-indent: -9999px;
        }
      }
    }

    .view-footer {
      text-align: right;
      padding-top: 30px;
    }
  }
}

.accept-help {
  margin-right: 20px;
  font-size: $very-small;
  color: $dark-grey;

  span {
    color: $green;
    font-size: $normal;
    font-weight: 700;
    padding-right: 5px;
  }
}

.reject-help {
  font-size: $very-small;
  color: $dark-grey;

  span {
    color: #AF0A0D;
    font-size: $normal;
    font-weight: 700;
    padding-right: 5px;
  }
}

#mediators-registry-order {
  width: 400px;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0px 20px;

  th {
    text-align: center;
    color: $dark-grey;
  }

  tbody {
    tr {
      background: $white;
      // box-shadow: 0 0 7px rgba($black, 0.1);
      border-bottom: 0;
      position: relative;

      td {
        &:first-child {
          width:230px;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            z-index: -1;
            display: block;
            width: 400px;
            height: 66px;
            box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
            -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
            padding: 20px;
            margin-top: -23px;
            box-sizing: border-box;
          }
        }
      }
    }

    td {
      padding: 20px 15px;
      vertical-align: middle;
    }
  }
}

//PopUp in Mediator Requests//
.accept-link,
.reject-link {
  .popup-links-wrapper {
    display: none;
    opacity: 0;
    transition: opacity 1s;
  }

  &.open {
    .popup-links-wrapper {
      display: block;
      opacity: 1;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 12;
      width: 100vw;
      height: 100vh;
      background: rgba($black, 0.7);
    }

    .popup-links {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 400px;
      margin: 0 auto;
      background: $white;
      padding: 50px;

      .button a {
        background: $deep_blue;
        color: $white;
        font-size: $very-small;
        padding: 12px 40px;
        display: inline-block;
      }
    }

    .be-sure {
      margin: 40px 0 20px;
    }
  }

  .close-popup {
    position: absolute;
    top: 15px;
    right: 15px;
    border: 1px solid $border;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 32px;
    color: #888888;
    padding: 0 !important;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.logged-in {
  &.page-user {
    #page-title {
      border-bottom: none;
      padding-bottom: 0;
    }

    .profile {
      position: relative;
      h3 {
        display: none;
      }

      .field-label {
        margin-bottom: 15px;
        color: $dark-grey;
        font-weight: 400;
        width: 185px;
      }
    }

    .edit-profile {
      position: absolute;
      right: 0;
      top: 0;

      a {
        &:after {
          content: "";
          @include sprite($sprite-edit-icon-white);
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px;
        }

        &:hover,
        &:focus {
          &:after {
            // @include sprite($sprite-edit-icon-white);
          }
        }
      }
    }

    .new-request {
      position: absolute;
      right: 0;
      top: 0;
    }

    #user-profile-form {
      margin-left: 0;
    }

    .field-name-field-mediator-cv {
      img {
        display: none;
      }
    }

    .user-profile-category {
      .field-name-field-mediator-fields-expertise {
        .field-item {
          &:before {
            content: ",";
            margin-right: 5px;
          }

          &:first-child {
            &:before {
              content: none;
            }
          }
        }
      }
    }
  }
}

//Citizen's Request Form//
// .page-node-add-request {
  .node-request-form {
    position: relative;

    .filter-wrapper {
      padding: 0;
    }

    input {
      box-sizing: border-box;
      height: 40px;
      padding: 10px 15px;
      border: 1px solid $border;
      width: 100%;
      font-size: $small;
      color: $black;

      &::placeholder {
        color: $black;
      }

      &[disabled="disabled"] {
        background: $light-grey;
        border: none;
      }

      &[type="submit"] {
        background: $white;
        padding: 15px 60px;
        font-size: $small;
        height: auto;
        border: none;
        color: $black;
      }
    }

    .form-actions {
      width: 200px;

      input[type="submit"] {
        background: $deep_blue;
        padding: 15px;
        font-size: $small;
        height: auto;
        border: none;
        color: $white;
      }
    }

    textarea {
      box-sizing: border-box;
      padding: 10px 15px;
      border: 1px solid $border;
      width: 100%;
      height: 200px;
      font-size: $small;
      color: $black;
      resize: none;
      margin-bottom: 30px;
    }

    .grippie {
      display: none;
    }

    .form-item,
    .form-actions {
      margin: 0;
    }

    #edit-actions {
      margin-top: 60px;
    }

    .field-type-file {
      margin-bottom: 30px;

      img {
        display: none;
      }

      input {
        border: none;
        width: auto;

        &[type="submit"] {
          border: 1px solid $border;
          margin-left: 20px;
        }

        &[type="file"] {
          padding: 0 20px 0 0;
        }
      }
    }

    .large-title {
      > legend > .fieldset-legend {
        display: block;
        font-size: $slight;
        font-weight: 700;
        padding: 60px 0 40px;

        &:after {
          content: "";
          height: 3px;
          width: 40px;
          display: block;
          background: $orange;
        }
      }
    }

    label {
      margin-bottom: 10px;
      color: $dark-grey;
    }

    .field-name-field-suer-personal-info,
    .field-name-field-suers-lawyer-info,
    .field-name-field-defendant-personal-info,
    .field-name-field-defendants-lawyer-info {
      clear: left;

      .fieldset-legend {
        display: block;
        color: $cyan;
        font-size: $normal;
        padding: 20px 0 30px;
      }

      .form-wrapper {
        > .form-wrapper {
          float: left;
          width: span(4);
          margin: 0 gutter() 30px 0;

          &:nth-child(3n) {
            margin-right: 0;
          }

          &.field-name-body {
            clear: left;
            float: none;
            width: 100%;
          }
        }
      }
    }

    .group-top,
    .group-middle,
    .group-bottom {
      @include clearfix;

      .form-wrapper {
        float: left;
        width: span(4);
        margin: 0 gutter() 30px 0;

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }

    .group-help-text {
      > legend > .fieldset-legend {
        display: none;
      }

      .fieldset-description {
        position: relative;
        background: $light-grey;
        padding: 20px 20px 20px 55px;
        border-left: 5px solid $orange;
        margin: 10px 0 -30px;
        font-size: 15px;
        line-height: 1.6;

        &:before {
          content: "";
          @include sprite($sprite-info-box-icon);
          position: absolute;
          top: 22px;
          left: 20px;
        }
      }
    }
  }
// }

.node-type-request {
  .large-title {
    > legend > .fieldset-legend {
      display: block;
      font-size: $slight;
      font-weight: 700;
      padding: 30px 0 10px;

      &:after {
        content: "";
        height: 3px;
        width: 40px;
        display: block;
        background: $orange;
      }
    }
  }

  .field-name-field-suer-personal-info,
  .field-name-field-suers-lawyer-info,
  .field-name-field-defendant-personal-info,
  .field-name-field-defendants-lawyer-info {
    > .field-label {
      color: $cyan;
      font-size: $normal;
      padding: 20px 0;
    }
  }

  .field-name-field-defendants-lawyer-info {
    margin-bottom: 30px !important;
  }

  .node-request {
    .field {
      margin-bottom: 10px;

      .field-label {
        width: 180px;
        text-align: left;
        padding-right: 20px;
      }
    }

    .group-case-title {
      > legend .fieldset-legend {
        padding-bottom: 30px;
      }

      .field-name-body {
        .field-items {
          width: 83%;
        }
      }
    }
  }
}

form fieldset .formtips-processed,
.formtips-processed,
form .form-item div.formtips-processed {
  background: $white;
  color: $black;
  opacity: 1;
  box-shadow: 0 0 10px rgba($black, 0.3);
  padding: 20px 20px 17px;
  line-height: 1.7;
  font-size: $very-small;
  top: 25px;
  border: none;
  width: 100% !important;
  box-sizing: border-box;
}

.form-type-checkbox .formtips-wrapper {
  vertical-align: middle;
}

a.formtip {
  @include sprite($sprite-help-icon);
  float: right;
  margin-top: 3px;
  cursor: pointer;

  &:hover {
    @include sprite($sprite-help-icon-hover);
  }
}

.page-user,
.node-type-prototypes,
.page-mitrwo-diamesolavitwn,
.page-node-7743,
.page-node-7749,
.page-node-add-request,
.page-aitimata,
.node-type-request {

  #page-title {
    margin-top: 60px;
  }

  &.page-user-edit {
    #page-title {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  #user-login,
  #user-pass,
  #user-profile-form {
    max-width: 430px;
    margin: 0 auto;

    #edit-gsis {
      text-align: center;
    }

    input {
      box-sizing: border-box;
      height: 40px;
      padding: 10px 15px;
      border: 1px solid $border;
      width: 100%;
      font-size: $small;
      color: $black;

      &::placeholder {
        color: $black;
      }

      &[type="submit"] {
        padding: 15px 60px;
        font-size: $small;
        height: auto;
        border: none;
        color: $white;
      }

      &[type="checkbox"] {
        height: auto;
        width: auto;
      }
    }

    label {
      margin-bottom: 10px;
      color: $dark-grey;

      &.option {
        color: $black;
      }
    }

    .description {
      margin: 7px 0 0 0;
      color: $dark-grey;
    }

    .form-item {
      margin: 0 0 40px;
      width: 100%;

      .form-item {
        margin-bottom: 0;
      }
    }

    .password-strength {
      width: 100%;
      font-size: $very-small;
      margin: 0 0 15px;
    }

    .password-confirm {
      float: none;
      margin-bottom: 10px;
    }

    .password-suggestions {
      width: 100%;
      box-sizing: border-box;
      margin: 10px 0;
    }

    .field-name-field-mediator-cv {
      .file {
        a {
          width: 160px;
          display: inline-block;
          vertical-align: middle;
          word-break: break-all;
        }
      }

      .file-icon {
        display: none;
      }

      input[type="submit"] {
        background: $white;
        color: $black;
        border: 1px solid $border;
        margin-left: 20px;
        width: 187px;
      }

      .form-file {
        border: none;
        padding: 0;
        width: 220px;
      }
    }

    .field-name-field-mediator-county {
      .form-type-cshs {
        > .select-wrapper {
          margin-bottom: 40px;

          &:nth-child(3) {
            > label {
              display: none;
            }
          }
        }
      }

      .select-wrapper {
        margin: 0;
        display: block;
      }

    }
  }

  .description-bl {
    font-size: $small;
    text-align: center;
    margin: 40px 0;
  }

  .forgot-pass-sb {
    margin: 10px 0 0 0;
    display: inline-block;
  }
}

.page-user {
  #user-profile-form {
    .form-item {
      .form-item {
        margin-bottom: 7px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.page-user-edit-mediator {
  #user-profile-form {
    // max-width: 420px;
    margin: 0;

    input[disabled="disabled"] {
      background: $light-grey;
      border: none;
    }
  }

  .info-box {
    box-sizing: border-box;
    width: 1180px;
    margin-top: 30px;
  }
}

.selectboxit-container {
  width: 100%;

  .selectboxit {
    background: transparent;
    border: 1px solid $border;
    border-radius: 0;
    font-size: $small;
    font-family: $fira;
    color: $black;
    width: 100%;

    &.selectboxit-disabled {
      background: $light-grey;
      opacity: 1;
      border: none;
    }

    .selectboxit-text {
      max-width: 390px !important;
    }
  }

  .selectboxit-btn.selectboxit-enabled {
    &:hover,
    &:focus {
      background: transparent;
    }
  }

  span,
  .selectboxit-options a {
    height: 40px;
    line-height: 40px;
  }

  .selectboxit-options {
    border-radius: 0;
  }
}

input.form-checkbox {
  -webkit-appearance: none;
  border: 1px solid $border;
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  margin: 0 10px 0 0;
  line-height: 20px;

  &:checked {
    border: none !important;
    background: $cyan;

    &:before {
      content: "✓";
      display: block;
      color: $white;
      text-align: center;
      font-size: $normal !important;
    }
  }
}

// .messages.notification {
//   font-weight: 400;
//   border: 1px solid $border;
//   width: 900px;
// }

.page-user-login {
  .block-logged-in-profile-menu {
    display: none;
  }
}

.block-logged-in-profile-menu {
  border-bottom: 1px solid $border;
  margin-bottom: 40px;

  ul {
    margin: 0;

    li {
      display: inline-block;
      margin: 0 !important;
      padding: 0 30px;

      a {
        display: inline-block;
        font-size: $normal;
        font-weight: 400;
        color: $grey2;
        padding: 10px 0;
        position: relative;

        &.active {
          color: $black;

          &:after {
            content: "";
            width: 100%;
            height: 3px;
            display: block;
            background: $cyan;
            position: absolute;
            left: 0;
            bottom: -1px;
          }
        }

        &:hover,
        &:focus {
          text-decoration: none;
          color: $black;

          &:after {
            content: "";
            width: 100%;
            height: 3px;
            background: $cyan;
            position: absolute;
            left: 0;
            bottom: -1px;
          }
        }
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }
}

.page-mitrwo-diamesolavitwn {
  .view-mediator-search-users {
    .view-header {
      font-size: $slight;
      color: $deep_blue;
    }

    input {
      box-sizing: border-box;
      height: 40px;
      padding: 10px 15px;
      border: 1px solid $border;
      width: 100%;
      font-size: $small;
      color: $black;
      width: 240px;

      &::placeholder {
        color: $black;
      }

      &[type="submit"] {
        padding: 12px 60px;
        font-size: $small;
        height: auto;
        width: auto;
        border: none;
        background: $deep_blue;
        color: $white;
      }

      &[id="edit-reset"] {
        background: #708090;
        margin-top: 18px;
      }
    }

    .views-submit-button {
      margin-top: -1px;
    }

    .selectboxit {
      width: 240px;
    }
  }
}

//Frontpage//
.block-superfish {
  a.want-mediator {
    position: relative;
    padding: 16px 15px 10px !important;
    font-weight: 700;

    &:hover,
    &:focus {
      color: $white !important;
      text-decoration: underline !important;

      &:before {
        // background: $orange;
      }
    }

    &:before {
      content: "";
      background: $green;
      width: 100%;
      height: 43px;
      position: absolute;
      top: 1px;
      left: 0;
      z-index: -1;
    }
  }
}

.not-front {
  .block-superfish {
    a.want-mediator {
      &:before {
        height: 41px;
      }
    }
  }
}

.view-mediator-search-users {
  .views-row {
    position: relative;
    border-bottom: none;
    padding-bottom: 0;

    .views-field-nothing-1 {
      background: $white;
      box-shadow: 0 0 7px rgba($black, 0.1);
      padding: 10px 20px;
      border-radius: 20px;
    }

    .mediators {
      &--upper-section {
        @include clearfix;
      }

      &--bottom-section {
        border-top: 1px solid $border;
        margin-top: 10px;
        padding-top: 10px;
      }

      &--bottom-section-inner {
        @include clearfix;
      }

      &--field {
        float: left;
        padding: 10px;
      }

      &--label {
        color: $dark-grey;
      }

      &--column-2 {
        width: span(2);
      }

      &--column-3 {
        width: span(3);
      }

      &--name {
        color: $deep_blue;
        font-weight: 700;
      }

      &--expand {
        position: absolute;
        right: 30px;
        top: 26px;
        @include sprite($sprite-down-arrow);
      }

      &--expertise-field {
        padding: 10px;
      }

      &--print {
        position: relative;
        top: -10px;
        right: 10px;
        text-align: right;
        clear: left;
      }

      &--cv {
        clear: left;

        img {
          display: none;
        }
      }
    }

    &.open {
      .mediators--expand {
        @include sprite($sprite-up-arrow);
      }
    }
  }

  .view-footer {
    margin-top: 50px;
  }
}

.info-box {
  position: relative;
  background: $light-grey;
  padding: 20px 20px 20px 55px;
  border-left: 5px solid $orange;
  margin-bottom: 50px;
  font-size: 15px;
  line-height: 1.6;

  &:before {
    content: "";
    @include sprite($sprite-info-box-icon);
    position: absolute;
    top: 22px;
    left: 20px;
  }
}

.info-text {
  font-size: $very-small;
  font-style: italic;
  color: $dark-grey;
}

.round-button {
  a {
    font-size: $very-small;
    padding: 12px 40px;
    display: inline-block;
    border: 1px solid $green;
    border-radius: 30px;
    color: $white;
    background: $green;
    margin-left: 20px;

    &:hover,
    &:focus {
      // text-decoration: none;
      // background: $green;
      // color: $white;
    }
  }

  &.edit-profile {
    a {

    }
  }
}

.logged-in.page-user .download-certificate {
  margin-top: 55px;
  // display: none;

  a {
    padding: 12px 26px;

    &:after {
      @include sprite($sprite-download-file-white);
    }
  }
}

.block-mediator-list-download-files {
  margin-bottom: 35px;

  .download-full-list {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
  }

  .download-county-list {
    display: inline-block;
    vertical-align: middle;
  }

  .form-type-select {
    width: 250px;
    margin: 0 auto 30px;
  }

  .download-file {
    display: inline-block;
    font-size: $very-small;
    font-weight: 700;
    color: $deep_blue;
    background: $light-grey;
    border-radius: 20px;
    padding: 12px 20px;

    &:before {
      content: "";
      @include sprite($sprite-download-file);
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}

.modal-download-county-list-wrapper,
.modal-legal-download-county-list-wrapper {
  display: none;
  opacity: 0;
  transition: opacity 1s;

  &.open {
    display: block;
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
    width: 100vw;
    height: 100vh;
    background: rgba($black, 0.7);
  }

  .label-text {
    max-width: 230px;
    margin: 0 auto 20px;
    color: $dark-grey;
  }

  .download-file-popup {
    border-radius: 20px;
    background: $deep_blue;
    color: $white;
    font-size: $very-small;
    display: inline-block;
    padding: 10px 35px;
  }

  .close-popup {
    position: absolute;
    top: 15px;
    right: 15px;
    border: 1px solid $border;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 32px;
    color: #888888;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.modal-download-county-list,
.modal-legal-download-county-list {
  background: $white;
  box-shadow: 0 0 7px rgba($black, 0.1);
  padding: 60px 30px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  width: 350px;
  margin: 0 auto;
  text-align: center;
}

.node-news,
.node-events,
.node-page {
  .field-name-field-attachments {
    .field-item {
      display: inline-block;
      &:after {
        content: ",";
        margin-right: 5px;
      }

      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }
}

div.messages {
  margin-top: 30px;
}

.red {
  color: #AF0A0D;
  font-weight: 700;
}

.print-form {
  position: absolute;
  bottom: 0;
  right: 0;
  background-image: url('../images/print_mediator.png');
  width: 16px;
  height: 17px;
  color: transparent !important;
}


/*Cookies*/
#sliding-popup .eu-cookie-compliance-banner {
  padding: 15px 0;
  font-size: 0;

  #popup-text {
    font-size: $very-small;
    font-weight: 400;
    margin: 0;
  }

  #popup-buttons {
    margin: 0;

    button {
      background: $white;
      padding: 8px 20px;
      display: inline-block;
      color: $black !important;
      font-weight: 400;
      border-radius: 0;
      border: none;
      font-size: $very-small;
      margin: 0 0 0 15px;
      text-shadow: none;
      box-shadow: none;

      &:hover {
        background: $orange;
        color: $white !important;
      }
    }
  }
}

//Carrier new Submission

.page-node-add-ypopsifioi-diamesolavites,
.node-type-ypopsifioi-diamesolavites {
  .field-name-field-ipopsifios {
    tr {
      td {
        vertical-align: middle;

        input {
          max-width: 140px;
          margin-right: 6px;
        }
      }
    }

    & > .field-items {
      counter-reset: my-counter;

      & > .field-item {
        counter-increment: my-counter;
        display: flex;

        &:before {
          content: counter(my-counter);
          align-self: center;
        }

        & > .field-collection-view {
          width: 100%;
        }
      }
    }
  }

  .field-collection-view {
    .content {
      display: flex;
      justify-content: space-between;
    }
  }

  .field-collection-view-links {
    display: none;
  }

  .action-links-field-collection-add {
    display: none;
  }
}

//Erotimatologio

.node-type-erotimatologio {
  .page-title {
    margin-top: 60px;
  }
}

.page-node-add-erotimatologio {
  .page-title {
    margin-top: 60px;
  }

  .field-name-field-yas-start {
    display: none;
  }

  .field-name-field-yas-end {
    display: none;
  }
}

// Mediator registry selection.
#edit-profile-mediator-field-mediator-registry-select-und {
  display: flex;
}

// Mitroo nomikis voithias.

#edit-profile-mediator-field-mediator-nomiki-voithia-und {
  display: flex;
}

.download-legal-help-list {
  display: inline-block;
  margin-left: 20px;
  // display: none;
}

// Ektheseis pepragmenon

.node-type-ekthesi-pepragmenon {
  .tabs {
    display: none;
  }
}

#ekthesi-pepragmenon-node-form {

  .sticky-header {
    display: none;
  }

  .header-grouper{
    margin-left: 275px;
    th {
      text-align: center;
      border: 1px solid lightgrey;
      background-color: #004877;
      color: white;
    }

    .field-label-eiserxomenes {
      width: 178px;
    }

    .field-label-yas {
      width: 201px;
    }

    .field-label-apotelesma {
      width: 447px;
    }

    .field-label-diarkeia {
      width: 74px;
    }
  }
  .field-name-field-ektheseis-term {
    display: none;
  }

  .field-name-field-ektheseis-year {
    display: none;
  }
}

#field-ektheseis-yas-values {
  margin-bottom: 100px;
  border: 2px solid lightgrey;

  input {
    width: 50px;
    text-align: center;
  }

  thead {
    th {
      border: 1px solid lightgrey;
    }

    &>tr {
      &>th {
        &:first-of-type {
          display: none;
        }
      }
    }
  }

  tr {
    &>td {
      &:nth-of-type(2) {
        padding: 10px;
        width: 150px;

        input {
          width: 150px;
        }
      }
    }

    td {
      .form-item {
        text-align: center;
      }
    }
  }

  .field-ektheseis-eidi-diaforon {
    display: none;
  }

  .field-ektheseis-eidi-diaforon-display {
    input {
      background-color: #eeeeee;
      border: none;
      color: black;
    }
  }

  .field-label {
    padding-right: 5px;
    padding-left: 5px;

    label {
      max-width: 90px;
      margin: 0 auto;
      overflow-wrap: break-word;
      color: black;
    }
  }
}

#field-ektheseis-ekousia-values {
  border: 2px solid lightgrey;

  input {
    width: 50px;
    text-align: center;
  }

  thead {
    th {
      border: 1px solid lightgrey;
    }

    &>tr {
      &>th {
        &:nth-of-type(2) {
          display: none;
        }
      }
    }
  }

  tr {
    &>td {
      &:nth-of-type(2) {
        padding: 10px;
        width: 150px;

        input {
          width: 150px;
        }
      }
    }

    td {
      .form-item {
        text-align: center;
      }
    }
  }

  .form-disabled {
    input {
      background-color: #eee;
      border: none;
    }
  }

  .field-ekousia-eidi-diaforon {
    display: none;
  }

  .field-ektheseis-eidi-diaforon-display input {
    background-color: #eeeeee;
    border: none;
    color: black;
  }

  .field-label {
    padding-right: 5px;
    padding-left: 5px;
  }

  label {
    max-width: 90px;
    margin: 0 auto;
    overflow-wrap: break-word;
    color: black;
  }
}

.field-name-field-ektheseis-yas,
.field-name-field-ektheseis-ekousia {
  .sticky-header {
    thead {
      th {
        border: 1px solid lightgrey;
      }

      &>tr {
        &>th {
          &:nth-of-type(2) {
            display: none;
          }
        }
      }
    }

    .field-label {
      padding-right: 5px;
      padding-left: 5px;
    }

    label {
      max-width: 90px;
      margin: 0 auto;
      overflow-wrap: break-word;
      color: black;
    }
  }
}

.view-ektheseis-pepragmenon {

  .sticky-header {
    display: none;
  }

  #edit-field-ektheseis-year-value-wrapper {
    min-width: 145px;
  }
  .views-exposed-form{
    margin-bottom: 50px;

    .form-submit {
      margin-top: 2.6em;
    }
  }

  tr {
    background-color: unset;
    padding: 0;
  }

  .attachment {
    margin-top: 50px;
  }

  .field-collection-view-final {
    border: 2px solid lightgrey;

    thead {
      tr {
        th {
          border: 1px solid lightgrey;
          padding: 0 5px;
        }
      }
    }

    tbody {
      tr {
        td {
          border: 1px solid lightgrey;
          text-align: center;
          vertical-align: middle;
        }
      }
    }
  }
}

.logged-in.page-user {
  .profile {
    .field-name-field-mediator-cv {
      .field-label {
        margin-bottom: 3px;
      }
    }
  }
}

.page-ypovoles-diamesolaviton {
  .date-year{
    width: 70px;
  }
}


.page-node-add-ypovoli-diamesolaviton,
.page-node-edit.node-type-ypovoli-diamesolaviton{
  .field-name-field-ypopsifios {

    .fieldset-legend{
      display: none;
    }
    .sticky-table {
      width: 120%;
      td {
        vertical-align: middle;
      }
      input{
        width: 92%;
      }

      .tabledrag {
        display: none;
      }

      .tabledrag-toggle-weight-wrapper {
        display: none;
      }

      .field-multiple-drag {
        display: none;
      }
    }
  }
  .field-name-field-file-aitisi,
  .field-name-field-file-pistopoiitiko,
  .field-name-field-file-paravolo{
    .file-widget {
      min-width: 170px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      //.form-submit {
      //  visibility: hidden;
      //  height: 0;
      //  padding: 0;
      //}
    }

    label {
      display:none
    }
  }
}

.page-node-edit.node-type-ypovoli-diamesolaviton {
  .tabs {
    display: none;
  }
}

.field-name-field-periodos-ypovolis {
  width: 200px;
}
.page-ypovoles-diamesolaviton {
  table {
    width: 100%;
  }
}
