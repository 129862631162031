@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.node {
  &--full {
    ul {
      list-style: disc;
      padding-left: 1.2rem;
      margin-left: 1.2rem;
      margin-bottom: 1.2rem;
      overflow: hidden;

      li {
        margin-bottom: 0.5rem;
      }
    }

    ol {
      list-style: decimal;
      padding-left: 1.2rem;
      margin-left: 1.2rem;
      margin-bottom: 1.2rem;

      li {
        margin-bottom: 0.5rem;
      }
    }
  }
}

strong {
  font-weight: 700;
}

u {
  text-decoration: line-through;
}

em {
  font-style: italic;
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

@mixin links($color,$hover,$decoration) {

  a {
    &:link, &:visited, &:active {
      text-decoration: none;
      color: $color;
    }

    &:hover, &:focus {
      color: $hover;
      text-decoration: $decoration;
    }
  }
}