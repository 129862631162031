// Fonts

@import url("http://fonts.googleapis.com/css?family=Fira+Sans:400,700,400italic,700italic&subset=latin,greek");
@import url("http://fonts.googleapis.com/css?family=Roboto+Condensed:400italic,700italic,400,700&subset=latin,greek-ext");

$fira: "Fira Sans", sans-serif;
$roboto: "Roboto Condensed", sans-serif;

// Font sizes

$very-small: 0.75rem; //12px
$msmall: 0.8125rem; // 13px
$normal: 1rem; // 16px
$slight: 1.125rem; // 18px;
$small: 0.875rem; // 14px
$menu: 1.25rem; // 20px
$search: 1.375rem; // 22px
$btitle: 1.625rem; // 26px