$cyan: #0081B5;
$deep_blue: #004877;
$orange: #F6891E;
$green: #1e6f0b;

$grey2: #aaaaaa;
$border: #dddddd;
$light-grey: #f7f7f7;
$dark-grey: #555555;

$black: #000000;
$white: #ffffff;