// Override default variables of susy

// Containers
$site-container: 1240px;

/* We can let susy calculate the container width in fluid by setting column width
 and gutters, else set the container variable here then change if necessary
 per case by case basis

  to show the grid to a spesific container we can use this too

  @include show-grid(background);

  THEN, note

  @include container(show); // this also works but only on container mixin, without show-grid

  @include container((debug:(image: show))); // maybe output background too or color if needed
  @include layout((debug:(image: show))); // changes the layout globally, useful

  using shorthand syntax it can also be
  @include container(show));

  We can construct layout settings by using layout function and shorthand syntax like so
  $my_layout: layout(6 1/4 show); //find keywords for use here -> http://susydocs.oddbird.net/en/latest/shorthand

  example uses
  @include container($my_layout);

  For ISOLATION

  Either use isolate() mixin or isolate keyword in span mixin

  Spread: narrow, wide, wider

  How the grid spans. If narrow it only spans in columns, if wide it spans in columns plus 1 external gutter, if wider
  it spans in columns plus both external gutters. For example if gutter-position is inside, spread is set to wider by
  default

  */

$susy: (
        container: $site-container,
        columns: 12,
        column-width: 83px,
  /* gutters are used as a ratio of the column width or a pair of gutters(px) / column(px), whether you
     use a ratio or a pair of static 20px/80px values the calculation if fluid is in percentages  */
        gutters: 24px/83px,
       /* gutters: 1/4, */
        global-box-sizing: content-box,
        debug: (
          image: hide,
          color: rgba(#66f, .25),
          output: background,
          toggle: top right,
        ),
        // breakpoint true looks for a breakpoint mixin provided by a third party
        // clearfix uses a custom mixin if it is named clearfix
        use-custom: (
          clearfix: true,
          breakpoint: true,
        )
  // math: fluid,
  // output: float,
  // gutter-position: inside,
);

/* if we use spans in outer divs, in inner divs we need to find avaiable columns either by of, nested or maybe with-layout
@include nested(6) { etc etc} */

// Switch breakpoints to desktop first
@include breakpoint-set('default feature', 'max-width');