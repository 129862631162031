// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sprite-accept-icon-name: 'sprite_accept-icon';
$sprite-accept-icon-x: 57px;
$sprite-accept-icon-y: 0px;
$sprite-accept-icon-offset-x: -57px;
$sprite-accept-icon-offset-y: 0px;
$sprite-accept-icon-width: 25px;
$sprite-accept-icon-height: 19px;
$sprite-accept-icon-total-width: 114px;
$sprite-accept-icon-total-height: 81px;
$sprite-accept-icon-image: '../images/sprites/build/sprites.png';
$sprite-accept-icon: (57px, 0px, -57px, 0px, 25px, 19px, 114px, 81px, '../images/sprites/build/sprites.png', 'sprite_accept-icon', );
$sprite-anon-login-name: 'sprite_anon_login';
$sprite-anon-login-x: 18px;
$sprite-anon-login-y: 65px;
$sprite-anon-login-offset-x: -18px;
$sprite-anon-login-offset-y: -65px;
$sprite-anon-login-width: 15px;
$sprite-anon-login-height: 16px;
$sprite-anon-login-total-width: 114px;
$sprite-anon-login-total-height: 81px;
$sprite-anon-login-image: '../images/sprites/build/sprites.png';
$sprite-anon-login: (18px, 65px, -18px, -65px, 15px, 16px, 114px, 81px, '../images/sprites/build/sprites.png', 'sprite_anon_login', );
$sprite-down-arrow-name: 'sprite_down-arrow';
$sprite-down-arrow-x: 35px;
$sprite-down-arrow-y: 29px;
$sprite-down-arrow-offset-x: -35px;
$sprite-down-arrow-offset-y: -29px;
$sprite-down-arrow-width: 20px;
$sprite-down-arrow-height: 10px;
$sprite-down-arrow-total-width: 114px;
$sprite-down-arrow-total-height: 81px;
$sprite-down-arrow-image: '../images/sprites/build/sprites.png';
$sprite-down-arrow: (35px, 29px, -35px, -29px, 20px, 10px, 114px, 81px, '../images/sprites/build/sprites.png', 'sprite_down-arrow', );
$sprite-download-file-white-name: 'sprite_download-file-white';
$sprite-download-file-white-x: 84px;
$sprite-download-file-white-y: 0px;
$sprite-download-file-white-offset-x: -84px;
$sprite-download-file-white-offset-y: 0px;
$sprite-download-file-white-width: 18px;
$sprite-download-file-white-height: 18px;
$sprite-download-file-white-total-width: 114px;
$sprite-download-file-white-total-height: 81px;
$sprite-download-file-white-image: '../images/sprites/build/sprites.png';
$sprite-download-file-white: (84px, 0px, -84px, 0px, 18px, 18px, 114px, 81px, '../images/sprites/build/sprites.png', 'sprite_download-file-white', );
$sprite-download-file-name: 'sprite_download-file';
$sprite-download-file-x: 62px;
$sprite-download-file-y: 43px;
$sprite-download-file-offset-x: -62px;
$sprite-download-file-offset-y: -43px;
$sprite-download-file-width: 18px;
$sprite-download-file-height: 18px;
$sprite-download-file-total-width: 114px;
$sprite-download-file-total-height: 81px;
$sprite-download-file-image: '../images/sprites/build/sprites.png';
$sprite-download-file: (62px, 43px, -62px, -43px, 18px, 18px, 114px, 81px, '../images/sprites/build/sprites.png', 'sprite_download-file', );
$sprite-download-icon-name: 'sprite_download-icon';
$sprite-download-icon-x: 35px;
$sprite-download-icon-y: 0px;
$sprite-download-icon-offset-x: -35px;
$sprite-download-icon-offset-y: 0px;
$sprite-download-icon-width: 20px;
$sprite-download-icon-height: 27px;
$sprite-download-icon-total-width: 114px;
$sprite-download-icon-total-height: 81px;
$sprite-download-icon-image: '../images/sprites/build/sprites.png';
$sprite-download-icon: (35px, 0px, -35px, 0px, 20px, 27px, 114px, 81px, '../images/sprites/build/sprites.png', 'sprite_download-icon', );
$sprite-edit-icon-white-name: 'sprite_edit-icon-white';
$sprite-edit-icon-white-x: 42px;
$sprite-edit-icon-white-y: 43px;
$sprite-edit-icon-white-offset-x: -42px;
$sprite-edit-icon-white-offset-y: -43px;
$sprite-edit-icon-white-width: 18px;
$sprite-edit-icon-white-height: 18px;
$sprite-edit-icon-white-total-width: 114px;
$sprite-edit-icon-white-total-height: 81px;
$sprite-edit-icon-white-image: '../images/sprites/build/sprites.png';
$sprite-edit-icon-white: (42px, 43px, -42px, -43px, 18px, 18px, 114px, 81px, '../images/sprites/build/sprites.png', 'sprite_edit-icon-white', );
$sprite-edit-icon-name: 'sprite_edit-icon';
$sprite-edit-icon-x: 84px;
$sprite-edit-icon-y: 20px;
$sprite-edit-icon-offset-x: -84px;
$sprite-edit-icon-offset-y: -20px;
$sprite-edit-icon-width: 18px;
$sprite-edit-icon-height: 18px;
$sprite-edit-icon-total-width: 114px;
$sprite-edit-icon-total-height: 81px;
$sprite-edit-icon-image: '../images/sprites/build/sprites.png';
$sprite-edit-icon: (84px, 20px, -84px, -20px, 18px, 18px, 114px, 81px, '../images/sprites/build/sprites.png', 'sprite_edit-icon', );
$sprite-facebook-icon-name: 'sprite_facebook-icon';
$sprite-facebook-icon-x: 104px;
$sprite-facebook-icon-y: 0px;
$sprite-facebook-icon-offset-x: -104px;
$sprite-facebook-icon-offset-y: 0px;
$sprite-facebook-icon-width: 10px;
$sprite-facebook-icon-height: 20px;
$sprite-facebook-icon-total-width: 114px;
$sprite-facebook-icon-total-height: 81px;
$sprite-facebook-icon-image: '../images/sprites/build/sprites.png';
$sprite-facebook-icon: (104px, 0px, -104px, 0px, 10px, 20px, 114px, 81px, '../images/sprites/build/sprites.png', 'sprite_facebook-icon', );
$sprite-file-icon-name: 'sprite_file-icon';
$sprite-file-icon-x: 0px;
$sprite-file-icon-y: 0px;
$sprite-file-icon-offset-x: 0px;
$sprite-file-icon-offset-y: 0px;
$sprite-file-icon-width: 33px;
$sprite-file-icon-height: 41px;
$sprite-file-icon-total-width: 114px;
$sprite-file-icon-total-height: 81px;
$sprite-file-icon-image: '../images/sprites/build/sprites.png';
$sprite-file-icon: (0px, 0px, 0px, 0px, 33px, 41px, 114px, 81px, '../images/sprites/build/sprites.png', 'sprite_file-icon', );
$sprite-help-icon-hover-name: 'sprite_help-icon-hover';
$sprite-help-icon-hover-x: 84px;
$sprite-help-icon-hover-y: 40px;
$sprite-help-icon-hover-offset-x: -84px;
$sprite-help-icon-hover-offset-y: -40px;
$sprite-help-icon-hover-width: 16px;
$sprite-help-icon-hover-height: 16px;
$sprite-help-icon-hover-total-width: 114px;
$sprite-help-icon-hover-total-height: 81px;
$sprite-help-icon-hover-image: '../images/sprites/build/sprites.png';
$sprite-help-icon-hover: (84px, 40px, -84px, -40px, 16px, 16px, 114px, 81px, '../images/sprites/build/sprites.png', 'sprite_help-icon-hover', );
$sprite-help-icon-name: 'sprite_help-icon';
$sprite-help-icon-x: 0px;
$sprite-help-icon-y: 65px;
$sprite-help-icon-offset-x: 0px;
$sprite-help-icon-offset-y: -65px;
$sprite-help-icon-width: 16px;
$sprite-help-icon-height: 16px;
$sprite-help-icon-total-width: 114px;
$sprite-help-icon-total-height: 81px;
$sprite-help-icon-image: '../images/sprites/build/sprites.png';
$sprite-help-icon: (0px, 65px, 0px, -65px, 16px, 16px, 114px, 81px, '../images/sprites/build/sprites.png', 'sprite_help-icon', );
$sprite-info-box-icon-name: 'sprite_info-box-icon';
$sprite-info-box-icon-x: 57px;
$sprite-info-box-icon-y: 21px;
$sprite-info-box-icon-offset-x: -57px;
$sprite-info-box-icon-offset-y: -21px;
$sprite-info-box-icon-width: 20px;
$sprite-info-box-icon-height: 20px;
$sprite-info-box-icon-total-width: 114px;
$sprite-info-box-icon-total-height: 81px;
$sprite-info-box-icon-image: '../images/sprites/build/sprites.png';
$sprite-info-box-icon: (57px, 21px, -57px, -21px, 20px, 20px, 114px, 81px, '../images/sprites/build/sprites.png', 'sprite_info-box-icon', );
$sprite-reject-icon-name: 'sprite_reject-icon';
$sprite-reject-icon-x: 22px;
$sprite-reject-icon-y: 43px;
$sprite-reject-icon-offset-x: -22px;
$sprite-reject-icon-offset-y: -43px;
$sprite-reject-icon-width: 18px;
$sprite-reject-icon-height: 19px;
$sprite-reject-icon-total-width: 114px;
$sprite-reject-icon-total-height: 81px;
$sprite-reject-icon-image: '../images/sprites/build/sprites.png';
$sprite-reject-icon: (22px, 43px, -22px, -43px, 18px, 19px, 114px, 81px, '../images/sprites/build/sprites.png', 'sprite_reject-icon', );
$sprite-rss-icon-name: 'sprite_rss-icon';
$sprite-rss-icon-x: 0px;
$sprite-rss-icon-y: 43px;
$sprite-rss-icon-offset-x: 0px;
$sprite-rss-icon-offset-y: -43px;
$sprite-rss-icon-width: 20px;
$sprite-rss-icon-height: 20px;
$sprite-rss-icon-total-width: 114px;
$sprite-rss-icon-total-height: 81px;
$sprite-rss-icon-image: '../images/sprites/build/sprites.png';
$sprite-rss-icon: (0px, 43px, 0px, -43px, 20px, 20px, 114px, 81px, '../images/sprites/build/sprites.png', 'sprite_rss-icon', );
$sprite-up-arrow-name: 'sprite_up-arrow';
$sprite-up-arrow-x: 52px;
$sprite-up-arrow-y: 65px;
$sprite-up-arrow-offset-x: -52px;
$sprite-up-arrow-offset-y: -65px;
$sprite-up-arrow-width: 20px;
$sprite-up-arrow-height: 10px;
$sprite-up-arrow-total-width: 114px;
$sprite-up-arrow-total-height: 81px;
$sprite-up-arrow-image: '../images/sprites/build/sprites.png';
$sprite-up-arrow: (52px, 65px, -52px, -65px, 20px, 10px, 114px, 81px, '../images/sprites/build/sprites.png', 'sprite_up-arrow', );
$sprite-user-area-name: 'sprite_user_area';
$sprite-user-area-x: 35px;
$sprite-user-area-y: 65px;
$sprite-user-area-offset-x: -35px;
$sprite-user-area-offset-y: -65px;
$sprite-user-area-width: 15px;
$sprite-user-area-height: 16px;
$sprite-user-area-total-width: 114px;
$sprite-user-area-total-height: 81px;
$sprite-user-area-image: '../images/sprites/build/sprites.png';
$sprite-user-area: (35px, 65px, -35px, -65px, 15px, 16px, 114px, 81px, '../images/sprites/build/sprites.png', 'sprite_user_area', );
$spritesheet-width: 114px;
$spritesheet-height: 81px;
$spritesheet-image: '../images/sprites/build/sprites.png';
$spritesheet-sprites: ($sprite-accept-icon, $sprite-anon-login, $sprite-down-arrow, $sprite-download-file-white, $sprite-download-file, $sprite-download-icon, $sprite-edit-icon-white, $sprite-edit-icon, $sprite-facebook-icon, $sprite-file-icon, $sprite-help-icon-hover, $sprite-help-icon, $sprite-info-box-icon, $sprite-reject-icon, $sprite-rss-icon, $sprite-up-arrow, $sprite-user-area, );
$spritesheet: (114px, 81px, '../images/sprites/build/sprites.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
